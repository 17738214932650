import { Announcement } from './Announcement';
import { FormMediaResource } from './Media';
import { MediaResource } from './MediaResource';
import { User } from './User';

export enum PodcastStatus {
    Draft = 0,
    AwaitingPreviewAudio = 1,
    AwaitingFinalAudio = 2,
    AwaitingDistribution = 3,
    Live = 4,
    Rejected = 5,
    GeneratingTranscript = 6
}

export interface PodcastCaption {
    content: string;
    podcast_id: number;
    video_voice_id: number;
}

export interface PodcastTopic {
    id: number;
    podcast_id: number;
    topic: string;
}

export enum PodcastStyleType {
    STANDARD = 1,
    INTERVIEW = 2
}

export interface PodcastAudioClip {
    title: string;
    url: string;
}

export interface Podcast {
    id: number;
    user_id: number;
    announcement_id: number;
    announcement: Announcement;
    status: PodcastStatus;
    summary: string;
    headline: string;
    modified: number | null;
    notification_published_sent: boolean | null;
    publish_time: number | null;
    is_publishable: boolean;
    is_live: boolean;
    is_editable: boolean;
    status_string: string;
    is_rejected: boolean;
    is_awaiting_audio_preview: boolean;
    media_resources: MediaResource[] | FormMediaResource[];
    preview_file: string | null;
    podcast_captions: PodcastCaption[];
    podcast_topics: PodcastTopic[];
    user: User;
    topic_1: string;
    topic_2: string;
    style_type: PodcastStyleType;
    audio_clip_url: string;
}
